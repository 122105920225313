import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import ChooseStar from './pages/ChooseStar';
import Star from './pages/Star';
import { useAuth } from './hooks/useAuth';
import { useEffect, useState } from 'react';
import { getSession } from './utils/auth';
import { StarProvider } from './contexts/StarContext';


const ProtectedRoute = ({ children }) => {
  const isLoggedIn = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return children;
};

const PublicRoute = ({ children }) => {
  const isLoggedIn = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/choose-star" />;
  }

  return children;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const session = getSession();
    if (session) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PublicRoute><Login /></PublicRoute>} />
        <Route path="/choose-star" element={<ProtectedRoute><ChooseStar /></ProtectedRoute>} />
        <Route path="/star/:selectedStar" element={<ProtectedRoute><Star /></ProtectedRoute>} />
      </Routes>
    </div>
  );
}

export default App;
