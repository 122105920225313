import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm/LoginForm';
import AlertSnackbar from '../components/AlertSnackbar/AlertSnackbar';
import { saveSession } from '../utils/auth';

const Login = () => {
    const [password, setPassword] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password === process.env.REACT_APP_KEY) {
            saveSession(process.env.REACT_APP_TOKEN);
            navigate('/choose-star');
        } else {
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div>
            <div className="flex justify-center items-center h-screen bg-gray-100">
                <div className="w-full max-w-xs">
                    <LoginForm password={password} setPassword={setPassword} onSubmit={handleSubmit} />
                </div>
                <AlertSnackbar open={openSnackbar} onClose={handleCloseSnackbar} message="Wrong Password" />
            </div>
        </div>
    );
};

export default Login;
