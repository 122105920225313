import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertSnackbar from '../components/AlertSnackbar/AlertSnackbar';
import StarSelector from '../components/StarSelector/StarSelector';
import Header from '../components/Header/Header';
import { clearSession } from '../utils/auth';
import { resetChatbot } from '../services/ApiService';

const ChooseStar = () => {
    const [selectedStar, setSelectedStar] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        if (selectedStar === '') {
            setOpenSnackbar(true);
            return;
        }
        await resetChatbot('1', selectedStar);
        navigate(`/star/${selectedStar}`);
    };

    const handleLogout = () => {
        clearSession();
        navigate('/');
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <div>
            <Header onLogout={handleLogout} isAdmin>
                <span></span>
            </Header>
            <div className="flex flex-col justify-center items-center h-screen bg-gray-100">
                <StarSelector selectedStar={selectedStar} setSelectedStar={setSelectedStar} />
                <button
                    onClick={handleSubmit}
                    className="bg-[#6C2B85] hover:bg-[#ac60ca] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Go
                </button>
            </div>
            <AlertSnackbar open={openSnackbar} onClose={handleCloseSnackbar} message="Please select a star" />
        </div>
    );
};

export default ChooseStar;
