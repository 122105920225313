import useStar from '../../hooks/useStar';


const StarSelector = ({ selectedStar, setSelectedStar }) => {
    const {stars} = useStar();
    const handleSelectChange = (event) => {
        setSelectedStar(event.target.value);
    };

    return (
        <div className="mb-4">
            <label htmlFor="star-select" className="block text-gray-700 text-sm font-bold mb-2">Choose a Star:</label>
            <select
                id="star-select"
                value={selectedStar}
                onChange={handleSelectChange}
                className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
            >
                <option value="" disabled>Select a Star</option>
                {stars.map((star, index) => (
                    <option key={index} value={star.star}>{star.name}</option>
                ))}
            </select>
        </div>
    );
};

export default StarSelector;
