export const saveSession = (token) => {
    localStorage.setItem('userSession', token);
};

export const getSession = () => {
    return localStorage.getItem('userSession');
};

export const clearSession = () => {
    localStorage.removeItem('userSession');
};
