import React, { useEffect, useState } from 'react';

const TypewriterEffect = ({ text }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
        setDisplayedText('');

        const intervalId = setInterval(() => {
            setDisplayedText((oldText) => {
                if (oldText.length < text.length) {
                    return oldText + text.charAt(oldText.length);
                } else {
                    clearInterval(intervalId);
                    return oldText;
                }
            });
        }, 50);

        return () => clearInterval(intervalId);
    }, [text]);

    return <span className='text-gray-500'>{displayedText}</span>;
};

export default TypewriterEffect;
